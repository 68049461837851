@import-normalize;

:root {
  font-size: 100%;
  font-family: "Source Sans Pro", sans-serif;
  --color_link: rgb(55, 141, 206);
  --color_link_selected: hsla(206, 61%, 32%, 1);
  --color_link_faded: rgba(80, 172, 242, 0.4);

  --color_background: #242528;
  --color_text: #3b3b3b;
  --color_text_faded: rgba(59, 59, 59, 0.2);
  --color_text_inverse: #fefefe;
  --color_disabled: #b6b2b2;
}

html {
  box-sizing: border-box;
  font-size: 10px;
  /* Prevent iOS bounce without disabling scroll ability:
     https://stackoverflow.com/questions/29894997/prevent-ios-bounce-without-disabling-scroll-ability
  */
  position: fixed;
  height: 100vh;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--color_background);
  color: var(--color_text);
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

dl,
dd {
  margin: 0;
}

p {
  margin: 0;
}

button {
  border: 0 none;
  padding: 0;
  background-color: transparent;
  touch-action: none;
}

button:hover {
  cursor: pointer;
}

a {
  color: var(--color_link);
}
